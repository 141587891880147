<template>
  <DetailTemplate
    :customClass="'contract-detail detail-page'"
    v-if="getPermission('contract:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="100"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            {{ contractTitle }}
          </h1>
          <template v-if="contractDetail.renewed">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  class="custom-status font-weight-600 custom-grey-border text-uppercase justify-center"
                  color="cyan"
                  text-color="white"
                  label
                >
                  Renewed
                </v-chip>
              </template>
              <span>Warranty Renewed</span>
            </v-tooltip>
          </template>
          <CustomStatus
            :status.sync="contractDetail.status"
            endpoint="contracts/status"
          ></CustomStatus>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="updateMoreAction('duplicate')"
      >
        Duplicate <v-icon small>mdi-content-copy</v-icon>
      </v-btn>
      <template v-if="!contractDetail.line_item">
        <v-btn
          :disabled="!formValid || formLoading || pageLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          v-on:click="updateMoreAction('edit')"
        >
          Edit <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <template v-else-if="contractDetail.status == 2">
        <v-btn
          :disabled="!formValid || formLoading || pageLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          v-on:click="updateMoreAction('renew')"
        >
          Renew <v-icon small>mdi-update</v-icon>
        </v-btn>
      </template>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="red lighted-1"
        v-on:click="updateMoreAction('delete')"
      >
        Delete <v-icon small>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-tabs
              active-class="custom-tab-active"
              v-model="contractTab"
              background-color="transparent"
              color="cyan"
              class="custom-tab-transparent tab-sticky"
            >
              <v-tab
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#other"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/contract.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Overview
              </v-tab>
              <template
                v-if="
                  !contractDetail.line_item && getPermission('line-item:view')
                "
              >
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#line-item"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/line-item.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Line Item
                </v-tab>
              </template>
              <template v-if="getPermission('attachment:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#attachment"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/attachment.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Attachment
                </v-tab>
              </template>
              <template v-if="getPermission('history:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#history"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/history.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  History
                </v-tab>
              </template>
            </v-tabs>
            <v-tabs-items v-model="contractTab">
              <v-tab-item value="other">
                <v-container fluid>
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title class="headline grey lighten-4">
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        Overview
                      </h3>
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <v-container fluid class="p-0">
                        <v-row>
                          <v-col md="6">
                            <table width="100%">
                              <tr>
                                <td
                                  width="200"
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Contract Subject
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  {{ contractDetail.subject }}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  width="200"
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Ref Job No.
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  <template v-if="contractDetail.soled_job_no">
                                    {{ contractDetail.soled_job_no }}
                                  </template>
                                  <template v-else>
                                    <em class="text--secondary"
                                      >No Ref Job No.</em
                                    >
                                  </template>
                                </td>
                              </tr>
                              <tr v-if="false">
                                <td
                                  width="200"
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Project #
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  <template
                                    v-if="
                                      lodash.isEmpty(contractDetail.project) ==
                                      false
                                    "
                                    >{{
                                      contractDetail.project.barcode
                                    }}</template
                                  >
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Project #</em
                                    ></template
                                  >
                                </td>
                              </tr>
                              <tr v-if="false">
                                <td
                                  width="200"
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Project Name
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  <template
                                    v-if="
                                      lodash.isEmpty(contractDetail.project) ==
                                      false
                                    "
                                    >{{ contractDetail.project.name }}</template
                                  >
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Project Name</em
                                    ></template
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td
                                  width="200"
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Customer #
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  <template
                                    v-if="
                                      lodash.isEmpty(contractDetail.customer) ==
                                      false
                                    "
                                    >{{
                                      contractDetail.customer.barcode
                                    }}</template
                                  >
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Customer #</em
                                    ></template
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td
                                  width="200"
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Customer Company
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  <template
                                    v-if="
                                      lodash.isEmpty(contractDetail.customer) ==
                                      false
                                    "
                                    >{{
                                      contractDetail.customer.company_name
                                    }}</template
                                  >
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Customer Company</em
                                    ></template
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td
                                  width="200"
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Display Name
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  <template
                                    v-if="
                                      lodash.isEmpty(contractDetail.customer) ==
                                      false
                                    "
                                    >{{
                                      contractDetail.customer.display_name
                                    }}</template
                                  >
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Display Name</em
                                    ></template
                                  >
                                </td>
                              </tr>
                            </table>
                          </v-col>
                          <v-col md="6">
                            <table width="100%">
                              <tr>
                                <td
                                  width="300 "
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Contract #
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  {{ contractDetail.barcode }}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  width="300 "
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Quotation Reference #
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  <template v-if="contractDetail.reference">{{
                                    contractDetail.reference
                                  }}</template>
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Quotation Reference #</em
                                    ></template
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td
                                  width="300 "
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Contract Type
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  <template
                                    v-if="contractDetail.contract_type"
                                    >{{
                                      contractDetail.contract_type
                                    }}</template
                                  >
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Contract Type</em
                                    ></template
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td
                                  width="300 "
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Contract Value
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  <template
                                    v-if="contractDetail.contract_value"
                                    >{{
                                      $accountingJS.formatMoney(
                                        contractDetail.contract_value
                                      )
                                    }}</template
                                  >
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Contract Value
                                    </em></template
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td
                                  width="300 "
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Duration
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  <template v-if="contractDetail.start_date">{{
                                    formatDate(contractDetail.start_date)
                                  }}</template>
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Start Date</em
                                    ></template
                                  >
                                  -
                                  <template v-if="contractDetail.end_date">{{
                                    formatDate(contractDetail.end_date)
                                  }}</template>
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No End Date</em
                                    ></template
                                  >
                                </td>
                              </tr>
                            </table>
                          </v-col>
                          <v-col
                            md="12"
                            class="py-0"
                            content="Description"
                            v-tippy="{ placement: 'top-start' }"
                          >
                            <read-more
                              class="custom-read-more custom-border-grey-dashed font-weight-600 font-size-18 p-4 custom-gray-color"
                              more-str="read more"
                              :text="
                                contractDetail.description ||
                                '<em>No Description</em>'
                              "
                              link="#"
                              less-str="read less"
                              :max-chars="200"
                            >
                            </read-more>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-container>

                <LineItemOnlyView
                  isContract
                  type="contract"
                  :detail.sync="contractDetail"
                  :pageLoading.sync="pageLoading"
                ></LineItemOnlyView>
              </v-tab-item>
              <template v-if="!contractDetail.line_item">
                <v-tab-item value="line-item">
                  <template v-if="lodash.isEmpty(contractDetail) === false">
                    <LineItemDetail
                      isContract
                      type="contract"
                      :detail.sync="contractDetail"
                      :pageLoading.sync="pageLoading"
                      v-on:load:parent="getContract"
                    ></LineItemDetail>
                  </template>
                </v-tab-item>
              </template>
              <v-tab-item value="attachment">
                <v-container fluid>
                  <FileTemplate
                    allowUpload
                    :allowDelete="false"
                    :attachments.sync="attachments"
                    v-on:file:updated="updateAttachment"
                  ></FileTemplate>
                </v-container>
              </v-tab-item>
              <v-tab-item value="history">
                <template v-if="lodash.isEmpty(contractDetail) === false">
                  <InternalHistoryDetail
                    type="contract"
                    :type_id.sync="contractDetail.id"
                  ></InternalHistoryDetail>
                </template>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <ContractDelete
        :deleteDialog.sync="contractDeleteDialog"
        :requestUrl.sync="contractDeleteRequestUrl"
        v-on:delete:success="contractDeleteSuccess"
        v-on:delete:close="contractDeleteDialog = false"
      ></ContractDelete>
      <Dialog
        :commonDialog="contractRenewDialog"
        :dialogWidth.sync="dialogWidth"
      >
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <p class="font-weight-500 font-size-18 color-custom-blue">
            Are you sure, you want to renew warranty ?
          </p>
          <div
            class="custom-border-top"
            v-html="contractDetail.description"
          ></div>
          <div class="custom-border-top">
            <v-form
              ref="warrantyForm"
              v-model.trim="formValid"
              lazy-validation
              v-on:submit.stop.prevent="renewWarranty"
            >
              <table class="width-100 mt-4">
                <tr>
                  <td colspan="3">
                    <label class="font-weight-600">Renew Duration</label>
                  </td>
                </tr>
                <tr
                  v-for="(warranty, index) in defaultItemWarranty"
                  :key="index"
                >
                  <td>
                    <v-text-field
                      dense
                      v-mask="'###'"
                      filled
                      label="Duration"
                      :rules="[
                        validateRules.required(warranty.value, 'Duration'),
                      ]"
                      solo
                      flat
                      :disabled="warrantyLoading"
                      :loading="warrantyLoading"
                      color="cyan"
                      class="pr-2 width-100"
                      v-model.trim="warranty.value"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-select
                      :items="warrantyDurationTypes"
                      dense
                      filled
                      solo
                      flat
                      :rules="[
                        validateRules.required(warranty.field, 'Duration Type'),
                      ]"
                      class="pl-2 pr-2 width-100"
                      v-model.trim="warranty.field"
                      label="Duration Type"
                      color="cyan"
                      :disabled="warrantyLoading"
                      :loading="warrantyLoading"
                      item-text="text"
                      item-value="value"
                      item-color="cyan"
                    ></v-select>
                  </td>
                  <td style="vertical-align: top">
                    <template v-if="index > 0">
                      <v-btn
                        class="mx-2"
                        color="red lighten-1"
                        dark
                        fab
                        :disabled="warrantyLoading"
                        small
                        v-on:click="removeWarranty(index)"
                      >
                        <v-icon dark> mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn
                        class="mx-2"
                        color="cyan"
                        dark
                        fab
                        :disabled="warrantyLoading"
                        small
                        v-on:click="pushWarranty"
                      >
                        <v-icon dark> mdi-plus</v-icon>
                      </v-btn>
                    </template>
                  </td>
                </tr>
              </table>
            </v-form>
          </div>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="!formValid || warrantyLoading"
            :loading="warrantyLoading"
            class="mx-2 custom-grey-border custom-bold-button text-white"
            color="red darken-1"
            v-on:click="renewWarranty"
          >
            Yes
          </v-btn>
          <v-btn
            :disabled="warrantyLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="contractRenewDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>
    </template>
  </DetailTemplate>
</template>
<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET,
  POST,
  PATCH,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import ContractDelete from "@/view/pages/partials/Delete-Request-Template.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import LineItemDetail from "@/view/pages/partials/Detail/Line-Item-Detail.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import LineItemOnlyView from "@/view/pages/partials/Line-Item-Only-View.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "contract-detail",
  title: "Detail Contract",
  data() {
    return {
      contract: null,
      contractTab: null,
      contractDeleteDialog: false,
      warrantyLoading: false,
      contractRenewDialog: false,
      attachments: new Array(),
      pageLoading: false,
      contractDetail: new Object({
        barcode: null,
        soled_job_no: null,
        reference: null,
        line_item: null,
        customer: new Object(),
        project: new Object(),
        subject: null,
        renewed: false,
        contract_value: null,
        contract_type: null,
        description: null,
        start_date: null,
        end_date: null,
        status: null,
        attachments: new Array(),
      }),
      defaultItemWarranty: new Array(),
      warrantyDurationTypes: [
        { text: "Day(s)", value: "day" },
        { text: "Week(s)", value: "week" },
        { text: "Month(s)", value: "month" },
        { text: "Year(s)", value: "year" },
      ],
    };
  },
  components: {
    LineItemOnlyView,
    DetailTemplate,
    LineItemDetail,
    InternalHistoryDetail,
    CustomStatus,
    Dialog,
    ContractDelete,
    FileTemplate,
  },
  methods: {
    pushWarranty() {
      if (this.defaultItemWarranty.length < this.warrantyDurationTypes.length) {
        this.defaultItemWarranty.push({
          warranty: 1,
          value: null,
          field: null,
          id: null,
        });
      }
    },
    removeWarranty(index) {
      this.defaultItemWarranty.splice(index, 1);
    },
    renewWarranty() {
      const _this = this;
      if (!_this.$refs.warrantyForm.validate()) {
        return false;
      }

      _this.$store.dispatch(CLEAR_ERROR, {});
      _this.warrantyLoading = true;
      let formData = new Object({
        warranty_data: _this.defaultItemWarranty,
      });

      _this.$store
        .dispatch(POST, {
          url: "contracts/" + _this.contract + "/warranty/renew",
          data: formData,
        })
        .then(() => {
          _this.contractRenewDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.warrantyLoading = false;
        });
    },
    contractDeleteSuccess() {
      this.contractDeleteDialog = false;
      this.goBack();
    },
    updateAttachment(attachments) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "contracts/" + _this.contract,
          data: { attachments },
        })
        .then(() => {
          _this.getContract();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "duplicate":
          _this.$router.push(
            _this.getDefaultRoute("contract.create", {
              query: {
                customer: _this.contractDetail.customer.id,
                duplicate: _this.contractDetail.id,
              },
            })
          );
          break;
        case "edit":
          _this.$router.push(
            _this.getDefaultRoute("contract.update", {
              query: { customer: _this.contractDetail.customer.id },
              params: {
                id: _this.contract,
              },
            })
          );
          break;
        case "delete":
          _this.contractDeleteDialog = true;
          break;
        case "renew":
          _this.contractRenewDialog = true;
          _this.defaultItemWarranty = new Array();
          _this.pushWarranty();
          break;
      }
    },
    getContract() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "contracts/" + _this.contract,
        })
        .then(({ data }) => {
          _this.contractDetail = new Object({
            id: data.id,
            barcode: data.barcode,
            soled_job_no: data.soled_job_no,
            reference: data.reference,
            customer: data.customer,
            renewed: data.renewed,
            line_item: data.line_item || null,
            project: data.project,
            subject: data.subject,
            contract_value: data.contract_value,
            contract_type: data.contract_type,
            description: data.description,
            status: data.status,
            start_date: data.start_date,
            end_date: data.end_date || "",
            attachments: new Array(),
            additional_remarks: data.additional_remarks,
            sub_total: data.sub_total,
            discount_amount: data.discount_amount,
            discount_value: data.discount_value,
            adjustment: data.adjustment,
            tax_amount: data.tax_amount,
            total: data.total,
          });
          _this.attachments = data.attachments;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Contract", route: "contract" },
      { title: "Detail" },
    ]);

    _this.contract = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (_this.contract <= 0) {
      _this.goBack();
    }

    _this.getContract();
  },
  computed: {
    contractDeleteRequestUrl() {
      return "contracts/" + this.contract;
    },
    contractTitle() {
      return this.contractDetail.subject;
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
